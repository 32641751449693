/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, Flex, Heading, Paragraph, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"

import type { SingleProductQuery } from "../../graphql-types"
import BackgroundSVG from "images/icons/background-light.svg"

import Layout from "components/Layout"
import ProductImageCarousel, {
  ProductImage,
} from "components/ProductImageCarousel"
import RetailerLocator from "components/Locator/RetailerLocator"
import SectionHeading from "components/SectionHeading"
import VideoSection from "components/VideoSection"

interface ProductPageProps extends PageProps {
  data: SingleProductQuery
}

const ProductPage = ({ data, location }: ProductPageProps) => {
  const isShotShell = data.strapiProduct?.categories?.find(
    e =>
      typeof e?.name === "string" &&
      e?.name !== null &&
      e?.name.toLowerCase() === "shotshell"
  )

  const shotgunInfoLine = [
    data.strapiProduct?.lengths?.name || "-",
    data.strapiProduct?.weight?.name || "-",
    data.strapiProduct?.shot_size?.name || "-",
  ].join(" | ")

  let productImages: ProductImage[] = []

  if (data.strapiProduct?.product_images) {
    for (let i = 0; i < data.strapiProduct?.product_images.length; i++) {
      const image = data.strapiProduct?.product_images[i]

      productImages.push({
        image: image?.localFile?.childImageSharp?.gatsbyImageData,
        imageAlt: image?.alternativeText as string,
      })
    }
  }

  const hasEnergyBallistics = (ballistics: any) => {
    if (ballistics.length <= 0) return false

    for (let i = 0; i < ballistics.length; i++) {
      const ballistic = ballistics[i]

      if (ballistic.energy === null) {
        return false
      }
    }

    return true
  }

  return (
    <Layout seo={data.strapiProduct?.seo} location={location}>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <BackgroundSVG
          sx={{
            position: "absolute",
            zIndex: -1,
            transformOrigin: "center",
            bottom: 0,
            left: 0,
            width: "auto",
            height: "100%",
            transform: "translate(-40%, 0)",
            "& > g": {
              opacity: 0.2,
            },
            "& *": {
              fill: "#d6d1c4cf",
            },
          }}
        />

        <Box as="section" sx={{ mt: ["20px", null, null, "60px"] }}>
          <Container>
            <Flex sx={{ flexDirection: ["column", null, null, "row"] }}>
              <Box
                sx={{
                  flex: ["1 100%", null, null, "1 60%"],
                  pr: [0, null, null, 10],
                }}
              >
                <Heading sx={{ color: "primary" }}>Aguila Ammunition</Heading>
                <Heading
                  as="h1"
                  variant="hero"
                  sx={{ color: "primary", mb: 8 }}
                >
                  {data.strapiProduct?.title}
                </Heading>

                {data.strapiProduct?.product_description && (
                  <Paragraph sx={{ mb: 8 }}>
                    {data.strapiProduct?.product_description}
                  </Paragraph>
                )}

                {data.strapiProduct?.bullet_type?.name && (
                  <Heading as="h3" variant="bodyTitle">
                    {data.strapiProduct?.bullet_type?.name}
                  </Heading>
                )}

                {!isShotShell && (
                  <Heading as="h3" variant="bodyTitle">
                    {data.strapiProduct?.weight?.name}
                  </Heading>
                )}

                {isShotShell && (
                  <Heading as="h3" variant="bodyTitle">
                    {shotgunInfoLine}
                  </Heading>
                )}

                <Heading as="h3" variant="bodyTitle">
                  # {data.strapiProduct?.sku}
                </Heading>
                <RetailerLocator
                  sx={{
                    my: [8, null, null, 12],
                    display: ["none", null, null, "flex"],
                  }}
                />
              </Box>
              <Box sx={{ flex: ["1 100%", null, null, "1 40%"] }}>
                {productImages.length > 0 && (
                  <ProductImageCarousel images={productImages} />
                )}
              </Box>
              <RetailerLocator sx={{ display: ["flex", null, null, "none"] }} />
            </Flex>
          </Container>
        </Box>

        <Box as="section" sx={{ my: 18 }}>
          <SectionHeading indented>Specifications</SectionHeading>
          <Container sx={{ py: 9 }}>
            <Flex
              sx={{
                flexDirection: ["column", null, null, "row"],
                ml: [4, null, 24],
                mr: 4,
                gap: 6,
                "& > div:not(:last-of-type)": {
                  pr: 6,
                  position: "relative",
                  "&:after": {
                    display: ["none", null, null, "block"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "60%",
                    width: "2px",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  },
                },
              }}
            >
              <Box
                sx={{
                  borderBottom: [
                    "2px solid rgba(35, 31, 32, 0.3)",
                    null,
                    null,
                    0,
                  ],
                  pl: 5,
                  pb: [3, null, null, 0],
                  flex: ["1", null, null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Bullet Type
                </Heading>
                <Paragraph
                  sx={{
                    mb: 0,
                    mt: 3,
                    pt: [0, null, null, 6],

                    textTransform: "uppercase",
                  }}
                >
                  {data.strapiProduct?.bullet_type?.name || "-"}
                </Paragraph>
              </Box>
              <Box
                sx={{
                  borderBottom: [
                    "2px solid rgba(35, 31, 32, 0.3)",
                    null,
                    null,
                    0,
                  ],
                  pl: 5,
                  pb: [3, null, null, 0],
                  flex: ["1", null, null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Firearm
                </Heading>
                {data.strapiProduct?.categories?.length === 0 && (
                  <Paragraph
                    sx={{
                      mb: 0,
                      mt: 0,
                      pt: 0,
                      textTransform: "uppercase",
                    }}
                  >
                    -
                  </Paragraph>
                )}
                {data.strapiProduct?.categories?.map((category, index) => (
                  <Paragraph
                    key={index}
                    sx={{
                      mb: 0,
                      mt: index === 0 ? 3 : 0,
                      pt: index === 0 ? [0, null, null, 6] : 0,
                      textTransform: "uppercase",
                    }}
                  >
                    {category?.name}
                  </Paragraph>
                ))}
              </Box>
              <Box
                sx={{
                  borderBottom: [
                    "2px solid rgba(35, 31, 32, 0.3)",
                    null,
                    null,
                    0,
                  ],
                  pl: 5,
                  pb: [3, null, null, 0],
                  flex: ["1", null, null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Application
                </Heading>
                {data.strapiProduct?.applications?.length === 0 && (
                  <Paragraph
                    sx={{ textTransform: "uppercase", py: [0, null, null, 6] }}
                  >
                    -
                  </Paragraph>
                )}
                {data.strapiProduct?.applications?.map((app, index) => (
                  <Paragraph
                    key={index}
                    sx={{
                      textTransform: "uppercase",
                      mt: 3,
                      pt: index === 0 ? [0, null, null, 6] : 0,
                    }}
                  >
                    {app?.name}
                  </Paragraph>
                ))}
              </Box>
              <Box
                sx={{
                  borderBottom: [
                    "2px solid rgba(35, 31, 32, 0.3)",
                    null,
                    null,
                    0,
                  ],
                  pl: 5,
                  pb: [3, null, null, 0],
                  flex: ["1", null, null, "1 1 25%"],
                }}
              >
                <Heading as="h2" variant="bodyTitle">
                  Packaging
                </Heading>
                <Paragraph
                  sx={{
                    textTransform: "uppercase",
                    mb: 0,
                    pt: [0, null, null, 6],
                  }}
                >
                  {data.strapiProduct?.packaging?.per_case} Per case
                </Paragraph>
                <Paragraph sx={{ textTransform: "uppercase", m: 0 }}>
                  {data.strapiProduct?.packaging?.per_box} Per Box
                </Paragraph>
              </Box>
            </Flex>
          </Container>
        </Box>

        <Box as="section" sx={{ position: "relative", my: 18 }}>
          <SectionHeading indented>Ballistics</SectionHeading>
          <Container sx={{ variant: "layout.narrow", py: [7, null, null, 9] }}>
            <table
              sx={{
                width: "100%",
                margin: "0 auto",
                maxWidth: hasEnergyBallistics(data.strapiProduct?.ballistics)
                  ? ["100%", null, "590px"]
                  : ["100%", null, "460px"],
                borderCollapse: "collapse",
                "& td": {
                  verticalAlign: "center",
                  display: ["flex", null, null, "table-cell"],
                  justifyContent: ["space-between", null, null, "normal"],
                  float: ["left", null, null, "none"],
                  width: ["100%", null, null, "auto"],
                  clear: ["both", null, null, "unset"],
                  padding: ["10px 0px", null, null, "10px"],
                  boxSizing: ["border-box", null, null, "initial"],
                  MozBoxSizing: ["border-box", null, null, "initial"],
                  WebkitBoxSizing: ["border-box", null, null, "initial"],
                  textTransform: "uppercase",
                },
                "& td:not(:last-of-type)": {
                  position: "relative",
                  "&:after": {
                    display: ["none", null, null, "block"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "100%",
                    width: "2px",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  },
                },
                "& td:not(:first-of-type)": {
                  pl: [0, null, null, 12],
                },
                "& thead": {
                  position: ["absolute", null, null, "static"],
                  left: ["-999em", null, null, "initial"],
                  top: ["-999em", null, null, "initial"],
                  width: "100%",
                  textAlign: "left",
                },
                "& thead th": {
                  variant: "text.bodyTitle",
                  padding: ["10px 5px", null, null, "10px 10px 30px 10px"],
                  pb: 8,
                },
                "& thead th:not(:first-of-type)": {
                  pl: [0, null, null, 12],
                },
                "& thead tr": {
                  display: ["static", null, null, "table-row"],
                },
                "& tbody": {
                  display: ["block", null, null, "revert"],
                },
                "& tr": {
                  display: ["block", null, null, "table-row"],
                },
                "& tr:not(:last-of-type)": {
                  position: "relative",
                  "&:after": {
                    display: ["block", null, null, "none"],
                    content: '""',
                    backgroundColor: "rgba(35,31,32,0.3)",
                    height: "2px",
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    left: 0,
                  },
                },
                "& td:before": {
                  content: "attr(data-th)",
                  display: ["block", null, null, "none"],
                  fontFamily: "bodyHeavy",
                  margin: "0 0 2px",
                },
                "& tbody tr": {
                  float: ["left", null, null, "none"],
                  width: ["100%", null, null, "auto"],
                  marginBottom: ["10px", null, null, 0],
                },
              }}
            >
              <thead>
                <tr>
                  <th scope="col">
                    Distance
                    <br />
                    (YARDS)
                  </th>
                  <th scope="col">
                    Velocity
                    <br />
                    (FPS)
                  </th>
                  {hasEnergyBallistics(data.strapiProduct?.ballistics) && (
                    <th scope="col">
                      Energy
                      <br />
                      (FT/LB)
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.strapiProduct?.ballistics?.map((info, index) => (
                  <tr key={index}>
                    <td data-th="Distance (YARDS)">{info?.distance}</td>
                    <td data-th="Velocity (FPS)">{info?.velocity}</td>
                    {info?.energy && (
                      <td data-th="ENERGY (FT/LB)">{info?.energy || "-"}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Container>
        </Box>

        {data.strapiProduct?.bulk_packs &&
          data.strapiProduct?.bulk_packs.length > 0 && (
            <Box as="section" sx={{ position: "relative", my: 18 }}>
              <SectionHeading indented>Bulk Packs</SectionHeading>
              <Container
                variant="layout.narrow"
                sx={{ py: [7, null, null, 9] }}
              >
                <table
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                    maxWidth: ["100%", null, "590px"],
                    borderCollapse: "collapse",
                    "& td": {
                      verticalAlign: "center",
                      display: ["flex", null, null, "table-cell"],
                      justifyContent: ["space-between", null, null, "normal"],
                      float: ["left", null, null, "none"],
                      width: ["100%", null, null, "auto"],
                      clear: ["both", null, null, "unset"],
                      padding: ["10px 0px", null, null, "10px"],
                      boxSizing: ["border-box", null, null, "initial"],
                      MozBoxSizing: ["border-box", null, null, "initial"],
                      WebkitBoxSizing: ["border-box", null, null, "initial"],
                      textTransform: "uppercase",
                    },
                    "& td:not(:last-of-type)": {
                      position: "relative",
                      "&:after": {
                        display: ["none", null, null, "block"],
                        content: '""',
                        backgroundColor: "rgba(35,31,32,0.3)",
                        height: "100%",
                        width: "2px",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      },
                    },
                    "& td:not(:first-of-type)": {
                      pl: [0, null, null, 12],
                    },
                    "& thead": {
                      position: ["absolute", null, null, "static"],
                      left: ["-999em", null, null, "initial"],
                      top: ["-999em", null, null, "initial"],
                      width: "100%",
                      textAlign: "left",
                    },
                    "& thead th": {
                      variant: "text.bodyTitle",
                      padding: ["10px 5px", null, null, "10px 10px 30px 10px"],
                      pb: 8,
                    },
                    "& thead th:not(:first-of-type)": {
                      pl: [0, null, null, 12],
                    },
                    "& thead tr": {
                      display: ["static", null, null, "table-row"],
                    },
                    "& tbody": {
                      display: ["block", null, null, "revert"],
                    },
                    "& tr": {
                      display: ["block", null, null, "table-row"],
                    },
                    "& tr:not(:last-of-type)": {
                      position: "relative",
                      "&:after": {
                        display: ["block", null, null, "none"],
                        content: '""',
                        backgroundColor: "rgba(35,31,32,0.3)",
                        height: "2px",
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                      },
                    },
                    "& td:before": {
                      content: "attr(data-th)",
                      display: ["block", null, null, "none"],
                      fontFamily: "bodyHeavy",
                      margin: "0 0 2px",
                    },
                    "& tbody tr": {
                      float: ["left", null, null, "none"],
                      width: ["100%", null, null, "auto"],
                      marginBottom: ["10px", null, null, 0],
                    },
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Sku</th>
                      <th scope="col">Per Box</th>
                      <th scope="col">Per Case</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.strapiProduct?.bulk_packs?.map((info, index) => (
                      <tr key={index}>
                        <td data-th="Sku">{info?.bulk_sku}</td>
                        <td data-th="Per Box">{info?.per_box}</td>
                        <td data-th="Per Case">{info?.per_case}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Container>
            </Box>
          )}

        {data.strapiProduct?.features && (
          <Box as="section" sx={{ my: 18 }}>
            <SectionHeading indented>FEATURES</SectionHeading>

            <Container
              sx={{ variant: "layout.narrow", py: [7, null, null, 9] }}
            >
              <Paragraph sx={{ m: 0 }}>
                {data.strapiProduct?.features}
              </Paragraph>
            </Container>
          </Box>
        )}

        {data.strapiProduct?.YouTubeVideo && (
          <VideoSection videoUrl={data.strapiProduct?.YouTubeVideo} />
        )}
      </Box>
    </Layout>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query SingleProduct($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      features
      product_description
      sku
      slug
      title
      YouTubeVideo
      applications {
        name
      }
      lengths {
        name
      }
      product_images {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      bulk_packs {
        bulk_sku
        per_box
        per_case
      }
      weight {
        name
      }
      shot_size {
        name
      }
      packaging {
        per_case
        per_box
      }
      ballistics {
        distance
        energy
        velocity
      }
      bullet_type {
        name
      }
      categories {
        name
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

/** @jsx jsx */
import ReactPlayer from "react-player"
import { AspectRatio, Box, Container, jsx } from "theme-ui"

interface VideoSectionProps {
  //enables sx prop to be passed down from parent
  className?: string
  videoUrl: string
}

const VideoSection = ({ className, videoUrl }: VideoSectionProps) => {
  return (
    <Box
      as="section"
      className={className}
      sx={{
        backgroundColor: "tan",
        py: [12, null, 20, 28],
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          height: "100%",
          overflow: "hidden",
          opacity: [0.5, null, 1, null, null],
        }}
      >
        <div
          sx={{
            // width: "clamp(128px, 20vw, 331px)",
            width: ["128px", null, "331px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            top: 0,
            right: "10%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["81px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "secondary",
            position: "absolute",
            top: "6%",
            right: "15%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["189px", null, "494px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "primary",
            position: "absolute",
            top: "26%",
            right: "10%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["61px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "primary",
            position: "absolute",
            bottom: "20%",
            left: 0,
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["189px", null, "382px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            top: "35%",
            right: "-10%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["82px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            bottom: -2,
            right: "50%",
            zIndex: 0,
            transform: "translateY(-50%) skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["82px", null, "141px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "text",
            position: "absolute",
            left: "5%",
            bottom: "5%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
        <div
          sx={{
            width: ["82px", null, "400px", null, null],
            height: ["6px", null, "16px", null, null],
            backgroundColor: "primary",
            position: "absolute",
            left: "10%",
            bottom: "8%",
            zIndex: 0,
            transform: "skew(-20deg)",
          }}
        />
      </Box>
      <Container variant="wide">
        <AspectRatio ratio={16 / 9}>
          <ReactPlayer
            className="react-player"
            url={videoUrl}
            width="100%"
            height="100%"
            sx={{
              left: 0,
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
            }}
          />
        </AspectRatio>
      </Container>
    </Box>
  )
}

export default VideoSection
